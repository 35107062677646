import {
  Box,
  Container,
  Flex,
  Text,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const eldoraGithub = () => {
    window.open("https://github.com/eldoraboo", "_blank", "noreferrer,noopener");
  };

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Flex}
        maxW={"6xl"}
        py={4}
        align="center"
        justify="space-between"
      >
        <Text>© 2024 Alexander Mai. All rights reserved</Text>
        <Text>Powered by{" "}
          <Link>
            <span style={{cursor: 'pointer'}} onClick={eldoraGithub}>@eldoraboo</span>
          </Link>
        </Text>
      </Container>
    </Box>
  );
}
